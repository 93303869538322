<template>
  <!-- 日历日程页 -->
  <el-card>
    <div class="calendar">
      <div class="top">
        <div class="one">
          <div class="title">项目数量</div>
          <div class="title">{{expCount}}</div>
          <div class="title">总用户数量</div>
          <div class="title">{{personTotalNum}}</div>
        </div>
        <div class="two">
          <div class="title">大连医科大学附属第一医院 </div>
          <div class="line">
            <div class="line-son">教师用户数</div>
            <div class="line-son">学生用户数</div>
          </div>
          <div class="line">
            <div class="line-son">{{DYTeacherTotalNum}}</div>
            <div class="line-son">{{DYStudentTotalNum}}</div>
          </div>
          <div class="title">大连医科大学</div>
          <div class="line">
            <div class="line-son">教师用户数</div>
            <div class="line-son">学生用户数</div>
          </div>
          <div class="line">
            <div class="line-son">{{DYSchoolTeacherTotalNum}}</div>
            <div class="line-son">{{DYSchoolStudentTotalNum}}</div>
          </div>
          <div class="title">共享</div>
          <div class="line">
            <div class="line-son">教师用户数</div>
            <div class="line-son">学生用户数</div>
          </div>
          <div class="line">
            <div class="line-son">{{ShareTeacherTotalNum}}</div>
            <div class="line-son">{{ShareStudentTotalNum}}</div>
          </div>
        </div>
        <div class="three">
          <div class="title">大连医科大学附属第一医院 </div>
          <div class="line">
            <div class="line-son">昨日学习人数</div>
            <!-- <div class="line-son">昨日新增注册人数</div> -->
          </div>
          <div class="line">
            <div class="line-son">{{DYOptNum}}</div>
            <!-- <div class="line-son">{{DYRegNum}}</div> -->
          </div>
          <div class="title">大连医科大学</div>
          <div class="line">
            <div class="line-son">昨日学习人数</div>
            <!-- <div class="line-son">昨日新增注册人数</div> -->
          </div>
          <div class="line">
            <div class="line-son">{{DYSchoolOptNum}}</div>
            <!-- <div class="line-son">{{DYSchoolRegNum}}</div> -->
          </div>
          <div class="title">共享</div>
          <div class="line">
            <div class="line-son">昨日学习人数</div>
            <!-- <div class="line-son">昨日新增注册人数</div> -->
          </div>
          <div class="line">
            <div class="line-son">{{ShareOptNum}}</div>
            <!-- <div class="line-son">{{ShareRegNum}}</div> -->
          </div>
        </div>
        <div class="four" >
          <div class="title"> 各校人数占比 </div>
          <div id="bing" class="four-son"></div>
        </div>
      </div>

      <div class="down" >
        <div class="title"> 最近30天访问量 </div>
        <div class="xian" id="xian"></div>
      </div>
    </div>
  </el-card>
</template>

<style lang="scss" scoped>
  @import "@/assets/css/DY/index.scss";
</style>

<script>
  import Calendar from "@/assets/js/DY/index.js";
  export default {
    ...Calendar
  };
</script>
