import { Message } from 'element-ui';
import * as echarts from 'echarts';
import { encryptCode, decryptCode } from '@/utils/function';
import { nprogressStart, nprogressClose } from '@/plugins/nprogress.js';

export default {
  name: 'index',
  components: {},
  data() {
    return {
      expCount: 0,
      personTotalNum: 0,
      xian: [],
      dates:[],
      bing: [],
      DYTeacherTotalNum:0,
      DYStudentTotalNum:0,
      DYSchoolTeacherTotalNum:0,
      DYSchoolStudentTotalNum:0,
      ShareTeacherTotalNum:0,
      ShareStudentTotalNum:0,
      DYSchoolOptNum:0,
      DYSchoolRegNum:0,
      DYOptNum:0,
      DYRegNum:0,
      ShareOptNum:0,
      ShareRegNum:0,
    };
  },
  created() {},
  mounted() 
  {
    this.getData();
  },
  methods: 
  {
    xianMethod()
    {
      const self = this;
      var chartDom = document.getElementById('xian');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        xAxis: {
          type: 'category',
          data: self.dates
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: self.xian,
            type: 'line',
            smooth: true
          }
        ]
      };

      option && myChart.setOption(option);
    },
    bingMethod()
    {
      const self = this;
      var chartDom = document.getElementById('bing');
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: '各校人数占比',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data:self.bing
          }
        ]
      };

      option && myChart.setOption(option);//*/
    },
    getData() 
    {
      let self = this;
      let data = {
        noticeValue: self.noticeContent,
      };
      // 开启loading
      nprogressStart();
      self.$Loading.show();
      self.axios.post('/index.php/backStatistics/getIndexData', data).then(res => {
        console.log(res)
        if (res.data.code > 0) 
        {
          self.expCount = res.data.data.expCount;
          self.personTotalNum = res.data.data.personTotalNum;
          self.xian = res.data.data.xian;
          self.bing = res.data.data.bing;
          self.dates = res.data.data.dates;
          self.DYTeacherTotalNum = res.data.data.DYTeacherTotalNum;
          self.DYStudentTotalNum = res.data.data.DYStudentTotalNum;
          self.DYSchoolTeacherTotalNum = res.data.data.DYSchoolTeacherTotalNum;
          self.DYSchoolStudentTotalNum = res.data.data.DYSchoolStudentTotalNum;
          self.ShareTeacherTotalNum = res.data.data.ShareTeacherTotalNum;
          self.ShareStudentTotalNum = res.data.data.ShareStudentTotalNum;
          self.DYSchoolOptNum = res.data.data.DYSchoolOptNum;
          self.DYSchoolRegNum = res.data.data.DYSchoolRegNum;
          self.DYOptNum = res.data.data.DYOptNum;
          self.DYRegNum = res.data.data.DYRegNum;
          self.ShareOptNum = res.data.data.ShareOptNum;
          self.ShareRegNum = res.data.data.ShareRegNum;

          this.bingMethod();
          this.xianMethod();

        }
        // 关闭loading
        nprogressClose();
        self.$Loading.hide();
      });
    },
  },
};
